import { AsyncPipe } from '@angular/common';
import { ChangeDetectionStrategy, Component } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import {
  CrmFormDataInputComponent,
  CrmFormDataWrapperComponent,
} from 'common-module/form/fragments';
import { CrmTranslatePipe } from 'common-module/translate';
import { DateTime } from 'luxon';

import { FormatDatePipe } from '../../pipes/format-date.pipe';

import { DateComponent } from './date.component';

export type FormDateOptions = Pick<
  DateComponent,
  'format' | 'disabledDate' | 'allowClear' | 'showTime'
>;

@Component({
  standalone: true,
  selector: 'app-form-date',
  template: `
    <crm-form-data-input-wrapper
      [status]="status$ | async"
      [error]="error$ | async"
      [config]="config"
    >
      @if (mode() === 'edit') {
        <app-date
          [formControl]="control"
          [placeholder]="config.label | crmTranslate"
          [format]="options?.format"
          [disabledDate]="options?.disabledDate"
          [allowClear]="options?.allowClear"
          [showTime]="options?.showTime"
        ></app-date>
      } @else {
        {{ data | formatDate }}
      }
    </crm-form-data-input-wrapper>
  `,
  imports: [
    CrmFormDataWrapperComponent,
    ReactiveFormsModule,
    CrmTranslatePipe,
    DateComponent,
    FormatDatePipe,
    AsyncPipe,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FormDateComponent extends CrmFormDataInputComponent<DateTime | null> {
  options?: FormDateOptions;
}
