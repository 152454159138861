import { type FormGroup } from '@angular/forms';
import {
  type CrmFormDataInputConfig,
  type CrmFormFragment,
  type CrmFormFragmentLayout,
} from 'common-module/form/fragments';
import { type Observable } from 'rxjs';

import { FormDateComponent, FormDateOptions } from './form-date.component';

/**
 * Function which returns date form fragment
 *
 * @param name
 * @param config
 * @param options
 * @param layout
 * @param visible$
 */
export const getFormDate = ({
  name,
  config,
  options,
  layout,
  visible$,
}: {
  name: string;
  config?: CrmFormDataInputConfig;
  options?: FormDateOptions;
  layout?: CrmFormFragmentLayout;
  visible$?: (form: FormGroup) => Observable<boolean>;
}): CrmFormFragment => ({
  type: 'data-input',
  name,
  content: FormDateComponent,
  contentContext: {
    config: config ?? {},
    options: options ?? { allowClear: true },
  },
  layout,
  visible$,
});
