import { FormGroup } from '@angular/forms';
import { CrmForm, CrmFormProvider } from 'common-module/form';
import { Subject } from 'rxjs';

import { InterfaceToGroup } from '../types/interface-to-group';

export type CustomForm<FormData> = Omit<CrmForm<FormData>, 'form'> & {
  form: FormGroup<InterfaceToGroup<FormData>>;
};

export type CustomFormRawValue<FormData> = ReturnType<
  CustomForm<FormData>['form']['getRawValue']
>;

export class CustomFormProvider<
  Input,
  FormData = Input,
> extends CrmFormProvider<Input, FormData> {
  override crmForm!: CustomForm<FormData>;
  override initialized$!: Subject<CustomForm<FormData>>;

  override get form(): CustomForm<FormData>['form'] {
    return super.form;
  }

  override get formValue(): CustomForm<FormData>['form']['value'] {
    return super.formValue;
  }

  override get formRawValue(): CustomFormRawValue<FormData> {
    return super.formRawValue;
  }

  override formInitialized(form: CustomForm<FormData>) {
    super.formInitialized(form);
  }
}
