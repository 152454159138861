import { Injectable } from '@angular/core';
import { CrmActionButtonsConfig } from 'common-module/buttons';
import { CrmModalFormProvider } from 'common-module/modal';
import { Observable, of } from 'rxjs';
import { CrmFormProvider } from 'common-module/form';
import { CrmSafeAny } from 'common-module/core/types';

@Injectable()
export abstract class ModalFormProvider<
  Result,
  FormProvider extends CrmFormProvider<CrmSafeAny>,
> extends CrmModalFormProvider<Result, FormProvider> {
  override readonly formProvider!: FormProvider;
  protected override saveTitle = 'generic.save';

  protected override getActionsConfig(): Observable<CrmActionButtonsConfig> {
    return of({
      actions: [
        this.cancelActionConfig({
          id: 'modal-cancel',
          size: 'default',
          title: 'generic.cancel',
          style: {},
          isLoading$: false,
          isDisabled$: this.isLoading$,
        }),
        this.saveActionConfig({ id: 'modal-save', size: 'default', style: {} }),
      ],
    });
  }

  protected setClosable(closable: boolean) {
    if (!closable) {
      this.formProvider.form.disable();
    } else {
      this.formProvider.form.enable();
    }

    this.modalRef.updateConfig({
      nzClosable: closable,
      nzMaskClosable: closable,
    });
  }
}
